//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
import DropdownView from './Dropdown';
import api from './API';
import { PageProps, Careers, Job } from './Model';
//Local Imports CSS

interface CareerState {
  careers: Careers;
  team: string;
  location: string;
}


export default class CareerView extends React.Component<PageProps, CareerState> {

  constructor(props: PageProps) {
    super(props);
    this.state = {
      team: "all",
      location: "all",
      careers: {
        team_filter: [],
        location_filter: [],
        jobs: []
      }
    };
    this.getJobs = this.getJobs.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.selectTeam = this.selectTeam.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
  }

  selectTeam(team: string) {
    this.setState({ team: team });
  }

  selectLocation(location: string) {
    this.setState({ location: location });
  }

  componentDidMount() {
    this.updateContent();
  }

  updateContent() {
    api.getCareers().then((careers) => {
      this.setState({
        careers: careers,
        team: careers.team_filter[0] || "all",
        location: careers.location_filter[0] || "all"
      });
    });
  }

  getJobs(): Array<Job> {
    let filteredJobs: Array<Job> = [];
    const location = this.state.location.toLowerCase();
    const team = this.state.team.toLowerCase();
    const jobs = this.state.careers.jobs || [];
    for (const job of jobs) {
      if (team.includes("all") && location.includes("all")) {
        filteredJobs.push(job);
      } else if (team.includes("all")) {
        if (job.location.toLowerCase().includes(location)) {
          filteredJobs.push(job);
        }
      } else if (location.includes("all")) {
        if (job.team.toLowerCase().includes(team)) {
          filteredJobs.push(job);
        }
      } else if (job.location.toLowerCase().includes(location) && job.team.toLowerCase().includes(team)) {
        filteredJobs.push(job);
      }
    }
    return filteredJobs;
  }


  render() {
    const jobs = this.getJobs();
    const locations = this.state.careers.location_filter || [];
    const teams = this.state.careers.team_filter || [];
    const location = this.state.location;
    const team = this.state.team;
    const jobContent = (jobs.length === 0) ? (<div className="no-job"><span>jobs currently not available</span></div>) : jobs.map((job, index) => {
      return (<JobAccordion {...job} key={index} />);
    });
    return (
      <div className="job">
        <div className="filters">
          <div className="filter-holder">
            <div className="filter-holder-text">Show Me</div>
            <DropdownView selected={team} items={teams} onSelected={this.selectTeam} />
          </div>
          <div className="filter-holder">
            <div className="filter-holder-text">positions located in</div>
            <DropdownView selected={location} items={locations} onSelected={this.selectLocation} />
          </div>
        </div>
        <div className="jd-holder">
          {jobContent}
        </div>
      </div>
    );
  }

}

interface JobShowState {
  show: boolean;
}

class JobAccordion extends React.Component<Job, JobShowState> {

  constructor(props: Job) {
    super(props);
    this.state = {
      show: false
    };
    this.changeShow = this.changeShow.bind(this);
    this.openLink = this.openLink.bind(this);
  }

  changeShow() {
    let show = this.state.show;
    this.setState({ show: !show });
  }

  openLink() {
    window.open(this.props.apply, "_blank");
  }

  render() {
    const job = this.props;
    const show = this.state.show;
    return (
      <div className="job-details" onClick={this.changeShow}>
        <div className="job-action">
          <div className="job-title">
            {job.title}
          </div>
          <div className="job-location">
            <div className="job-location-title">{job.location}</div>
            <div className="job-action-button" onClick={this.changeShow}>{(show) ? "x" : "+"}</div>
          </div>
        </div>
        <div className={(show) ? "job-details-content active" : "job-details-content"}>
          <div className="job-description">
            <div>{job.line}</div>
            <div>Responsibilities</div>
            <div>
              <ul>
                {
                  job.responsibility.map((value, index) => {
                    return (<li key={index}>{value}</li>);
                  })
                }
              </ul>
            </div>
            <div>Requirments</div>
            <div>
              <ul>
                {
                  job.requirment.map((value, index) => {
                    return (<li key={index}>{value}</li>);
                  })
                }
              </ul>
            </div>
          </div>
          <div className="job-button-holder">
            <button className="apply-button" onClick={this.openLink}>Apply Now</button>
          </div>
        </div>
      </div>
    );
  }
}