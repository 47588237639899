//Library Imports
import React from 'react';
import axios from 'axios';
//Library Imports CSS
//Local Imports
import Description from './Description';
//Local Imports CSS

export interface OverlayProps {
  close: (() => void);
}

export interface OverlayState {
  content: string;
}

export default class Overlay extends React.Component<OverlayProps, OverlayState> {

  constructor(props: OverlayProps) {
    super(props);
    this.state = {
      content: ""
    }
  }

  componentDidMount() {
    const path = window.location.pathname;
    const url = "/assets/data/" + (path.includes("terms") ? "terms.txt" : "privacy.txt");
    axios.get(url).then((response) => {
      this.setState({ content: response.data });
    });
  }

  render() {
    return (
      <div className="dialog-window">
        <span className="close" onClick={this.props.close}></span>
        <div className="overlay-window">
          <Description list={[this.state.content]}></Description>
        </div>
      </div >
    );
  }
}