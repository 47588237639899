//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
import { Gallery, PageProps } from './Model';
import api from './API';
import ProfileView from './Profile';
//Local Imports CSS


export default class GalleryView extends React.Component<PageProps, Gallery> {

  constructor(props: PageProps) {
    super(props);
    this.state = {
      profiles: []
    };
  }

  componentDidMount() {
    api.getGallery(this.props.name).then((gallery) => {
      this.setState(gallery);
    });
  }

  render() {
    const gallery = this.state;
    const size = gallery.profiles.length;
    return (
      <div className={"gallery-container-" + size}>
        {
          gallery.profiles.map((profile, index) => {
            return (
              <div className={"gallery-item" + (index + 1)} key={index}>
                <ProfileView {...profile} />
              </div>
            );
          })
        }
      </div>
    );
  }

}