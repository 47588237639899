//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
//Local Imports CSS

interface DropdownProps {
  selected: string;
  items: Array<string>;
  onSelected: (item: string) => void;
}

interface DropdownState {
  show: boolean;
}

export default class DropdownView extends React.Component<DropdownProps, DropdownState> {

  dropdown: React.RefObject<HTMLDivElement>;

  constructor(props: DropdownProps) {
    super(props);
    this.state = {
      show: false
    };
    this.changeShow = this.changeShow.bind(this);
    this.selected = this.selected.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.dropdown = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick(event: Event) {
    if (this.state.show) {
      if (!this.dropdown.current?.contains(event.target as Node)) {
        this.changeShow();
      }
    }
  }

  changeShow() {
    let show = this.state.show;
    this.setState({ show: !show });
  }

  selected(name: string) {
    this.changeShow();
    this.props.onSelected(name);
  }


  render() {
    const show = this.state.show;
    return (
      <div className="custom-dropdown-menu" ref={this.dropdown}>
        <div className="custom-dropdown-menu-selected">
          <span>{this.props.selected}</span>
          <span className={(show) ? "arrow-up" : "arrow-down"} onClick={this.changeShow}></span>
        </div>
        <div className={"custom-dropdown-menu-items " + ((show) ? "active" : "")}>
          {
            this.props.items.map((name, index) => {
              const active = (name === this.props.selected);
              return (<div className={"custom-dropdown-menu-item " + ((active) ? "active" : "")} key={index} onClick={() => { this.selected(name); }}>{name}</div>);
            })
          }
        </div>
      </div>
    );
  }
}
