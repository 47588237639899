//Library Imports
import React from 'react';
import { RouteComponentProps, Router, navigate } from '@reach/router';
//Library Imports CSS
//Local Imports
import MenuView from './Menu';
import Main from './Main';
import ProductView from './Product';
import Footer from './Footer';
import GalleryView from './Gallery';
import FlexBoxView from './FlexBox';
import CareerView from './Career';
import Overlay from './Overlay';
//Local Imports CSS

interface ScreenState {
  window: boolean;
  back: string;
}

export default class Screen extends React.Component<RouteComponentProps, ScreenState>  {

  constructor(props: any) {
    super(props);
    const path = window.location.pathname;
    const win = path.includes("terms") || path.includes("privacy");
    this.state = {
      window: win,
      back: "/"
    };
    this.openLink = this.openLink.bind(this);
    this.back = this.back.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
    window.openLink = this.openLink;
  }

  openLink(path: string, internal: boolean) {
    if (internal) {
      const win = path.includes("terms") || path.includes("privacy");
      if (win) {
        this.setState({
          window: true,
          back: window.location.pathname || "/"
        });
        navigate(path);
      } else {
        navigate(path);
      }
    } else {
      window.open(path, "_blank");
    }
  }

  showOverlay(path: string) {
    this.setState({
      window: true,
      back: window.location.pathname || "/"
    });
    window.openLink(path, true);
  }

  back() {
    const path = this.state.back || "/";
    this.setState({
      window: false,
      back: "/"
    });
    window.openLink(path, true);
    window.location.reload();
  }

  render() {
    const path = window.location.pathname.replace("/", "").toLowerCase();
    const overlay = (this.state.window) ? <Overlay close={this.back}></Overlay> : "";
    return (
      <div>
        <MenuView name={path} />
        <Router>
          <Home path="/" />
          <Team path="/team" />
          <Careers path="/careers" />
        </Router>
        {overlay}
      </div>
    );
  }

}


class Home extends React.Component<RouteComponentProps> {
  render() {
    return (
      <div className="container">
        <section><Main name="home-main" /></section>
        <section><ProductView name="home-mobius" /></section>
        <section><ProductView name="home-amend" /></section>
        <section><Footer name="footer" /></section>
      </div>
    );
  }
}
class Team extends React.Component<RouteComponentProps> {
  render() {
    return (
      <div className="container">
        <section className="tail-main-second-half"><Main name="tail-main" /></section>
        <section><GalleryView name="tiles" /></section>
        <section><FlexBoxView name="team" /></section>
        <section><Footer name="footer" /></section>
      </div>
    );
  }
}

class Careers extends React.Component<RouteComponentProps> {
  render() {
    return (
      <div className="container">
        <section className="blue-background"><CareerView name="career" /></section>
        <section><Footer name="footer" /></section>
      </div>
    );
  }
}