//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
import { Content, PageProps } from './Model';
import api from './API';
import Description from './Description';
//Local Imports CSS


export default class Main extends React.Component<PageProps, Content> {

  constructor(props: PageProps) {
    super(props);
    this.state = {
      title: "",
      description: [],
      imageCss1: "",
      imageCss2: "",
      showImage: false,
      responsive: 0,
      hasWhite: false
    };
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
    api.getHomeMain(this.props.name).then((content) => {
      this.setState(content);
    });
  }

  resize() {
    const width = (window.innerWidth <= 900) ? (window.innerWidth * 0.9) : 900;
    this.setState({ responsive: width });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  render() {
    //tail-main-first-half tail-main-second-half
    const content = this.state;
    const mobile = (window.innerWidth < 900);
    const display = content.showImage ? "" : (mobile) ? "hide" : "";
    const gridCss = (display === "") ? "grid-container" : "grid-container-hide";
    const whiteBackground = content.hasWhite ? "white-background" : "";
    const marginTop = content.hasWhite ? "less-margin-top" : "";
    const titleCSS = content.hasWhite ? "big-title-small " : "";
    return (
      <div className={gridCss}>
        <div className={"grid-content-view " + content.imageCss1}>
          <div className={whiteBackground}>
            <div className={"big-title  main-layer " + titleCSS + marginTop}>{content.title}</div>
            <div className="description"><Description list={content.description} /></div>
          </div>
        </div>
        <div className={"grid-image-view " + display + " " + content.imageCss2}>
        </div>
      </div>
    );
  }

}