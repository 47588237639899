//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
import { PageProps, MenuItem } from './Model';
import api from './API';
//Local Imports CSS

interface MenuState {
  menuItems: Array<MenuItem>;
  responsive: boolean;
}

export default class MenuView extends React.Component<PageProps, MenuState> {

  constructor(props: PageProps) {
    super(props);
    this.state = {
      menuItems: [],
      responsive: false
    };
    this.selectMenu = this.selectMenu.bind(this);
    this.responsive = this.responsive.bind(this);
  }

  componentDidMount() {
    api.getMenus().then((menuItems) => {
      menuItems.forEach((lItem: MenuItem) => {
        if (lItem.path === window.location.pathname) {
          lItem.active = true;
        } else {
          lItem.active = false;
        }
      });
      this.setState({ menuItems: menuItems });
    });
  }

  responsive() {
    let responsive = this.state.responsive;
    this.setState({ responsive: !responsive });
  }

  selectMenu(item: MenuItem) {
    let state = this.state;
    state.menuItems.forEach((lItem: MenuItem) => {
      if (lItem.path === item.path) {
        lItem.active = true;
      } else {
        lItem.active = false;
      }
    });
    this.setState(state);
    if (state.responsive) {
      this.responsive();
    }
    window.openLink(item.path, true);
  }

  render() {
    var menuItems = this.state.menuItems;
    var className = (this.state.responsive) ? "topnav  responsive" : "topnav";
    return (
      <div className="menubar">
        <div className={className}>
          {
            menuItems.map((menu, index) => {
              return (<MenuItemView {...menu} key={index} selected={() => this.selectMenu(menu)} />);
            })
          }
          <span className="icon" onClick={this.responsive}>Menu</span>
        </div>
      </div>
    );
  }
}

interface MenuItemProps extends MenuItem {
  selected: () => void;
}

class MenuItemView extends React.Component<MenuItemProps> {
  render() {
    var menu = this.props;
    var className = (menu.active) ? "active" : "";
    return (
      <span className={className} onClick={(e) => this.props.selected()}>{menu.name}</span>
    );
  }
}