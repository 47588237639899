import axios from "axios";
import { MenuItem, TypeObject, CacheObject, Content, Product, Footer, Gallery, Careers } from "./Model";

declare global {
  interface Window {
    openLink: ((path: string, internal: boolean) => void);
    showAlert: ((message: string) => void);
  }
}

class Api {

  host: string = "";

  cache: TypeObject = {};

  async fetchData(): Promise<CacheObject> {
    const response = await axios.get("/data.json");
    if (response.status === 200) {
      return new Promise((resolve, reject) => {
        this.cache = response.data;
        resolve({
          key: "server",
          data: response.data,
          error: false
        } as CacheObject);
      });
    } else {
      return new Promise((resolve, reject) => {
        reject({
          key: "server",
          data: response.data,
          error: true
        } as CacheObject);
      });
    }
  };

  async getFromCache(key: string): Promise<CacheObject> {
    if (this.cache[key]) {
      return new Promise((resolve, reject) => {
        resolve({
          key: key,
          data: this.cache[key],
          error: false
        } as CacheObject);
      });
    } else {
      const response = await this.fetchData();
      if (response.error) {
        return new Promise((resolve, reject) => {
          reject({
            key: key,
            data: response.data,
            error: true
          } as CacheObject);
        });
      } else {
        return new Promise((resolve, reject) => {
          resolve({
            key: key,
            data: this.cache[key],
            error: false
          } as CacheObject);
        });
      }
    }
  };

  async getMenus(): Promise<Array<MenuItem>> {
    const response = await this.getFromCache("menu");
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Array<MenuItem>);
      });
    }
  };

  async getHomeMain(name: string): Promise<Content> {
    const response = await this.getFromCache(name);
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Content);
      });
    }
  };

  async getProduct(name: string): Promise<Product> {
    const response = await this.getFromCache(name);
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Product);
      });
    }
  };

  async getGallery(name: string): Promise<Gallery> {
    const response = await this.getFromCache(name);
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Gallery);
      });
    }
  };

  async getFooter(): Promise<Footer> {
    const response = await this.getFromCache("footer");
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Footer);
      });
    }
  };

  async getCareers(): Promise<Careers> {
    const response = await this.getFromCache("career");
    if (response.error) {
      return new Promise((resolve, reject) => {
        reject(response.data);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(response.data as Careers);
      });
    }
  };

}

const api = new Api();
export default api;