//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
import { Gallery, PageProps, Profile } from './Model';
import api from './API';
import ProfileView from './Profile';
//Local Imports CSS


export default class FlexBoxView extends React.Component<PageProps, Gallery> {

  constructor(props: PageProps) {
    super(props);
    this.state = {
      profiles: []
    };
    this.chunkArray = this.chunkArray.bind(this);
  }

  componentDidMount() {
    api.getGallery(this.props.name).then((gallery) => {
      this.setState(gallery);
    });
  }

  chunkArray(array: Array<Profile>, size: number): Array<Array<Profile>> {
    return array.length > size
      ? [array.slice(0, size), ...this.chunkArray(array.slice(size), size)]
      : [array];
  }

  render() {
    const gallery = this.state;
    const maxElement = 3;
    const profiles = this.chunkArray(gallery.profiles, maxElement);
    return (
      <div className="flex-container">
        {
          profiles.map((subProfiles, index) => {
            return (<RowProfiles key={index} profiles={subProfiles} />);
          })
        }
      </div>
    );
  }

}

class RowProfiles extends React.Component<Gallery> {
  render() {
    return (
      <div className="flex-row">
        {
          this.props.profiles.map((profile, index) => {
            return (
              <div key={index}>
                <ProfileView {...profile} />
              </div>
            );
          })
        }
      </div>
    );
  }
}