//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
//Local Imports CSS

export interface DescriptionProps {
  list: Array<string>;
}

export default class Description extends React.Component<DescriptionProps> {
  render() {
    return (
      <div>
        {
          this.props.list.map((text, index) => {
            return (<p dangerouslySetInnerHTML={{ __html: text }} key={index}></p>);
          })
        }
      </div>
    );
  }
}