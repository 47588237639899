//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
import { Profile } from './Model';
import { LinkView } from './Footer';
import Description from './Description';
//Local Imports CSS



export default class ProfileView extends React.Component<Profile> {

  render() {
    const profile = this.props;
    const rounded = profile.rounded ? "profile-rounded " : "";
    return (
      <div className="full-profile">
        <div className={"profile " + rounded + profile.image}></div>
        <div className="profile-name">{profile.name}</div>
        <div className="profile-title">{profile.title}</div>
        <div className="profile-lines">
          <Description list={profile.lines} />
        </div>
        <div className="profile-icons">
          {
            profile.apps.map((link, index) => {
              return (<LinkView {...link} key={index} />)
            })
          }
        </div>
      </div>
    );
  }
}