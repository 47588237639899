//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
import { PageProps, Footer, Link, Address } from './Model';
import api from './API';
import Description from './Description';
//Local Imports CSS


/*
*/
export default class FooterView extends React.Component<PageProps, Footer> {

  constructor(props: PageProps) {
    super(props);
    this.state = {
      message: "Say hello.",
      reach: "REACH OUT TO US AT: ",
      email: "founders@typetail.com",
      addresses: [],
      leftLinks: [],
      rightLinks: []
    };
  }

  componentDidMount() {
    api.getFooter().then((footer) => {
      this.setState(footer);
    });
  }


  render() {
    const footer = this.state;
    const items = ["address-grid-content-view", "address-grid-image-view"];
    return (
      <div className="footer-layer">
        <div className="footer-content">
          <div className="big-title white-font footer-message">{footer.message}</div>
          <div className="padding-top">
            <span className="uppercase">{footer.reach}</span>
            <span className="footer-email">{footer.email}</span>
          </div>
          <div className="address-grid-container">
            {
              footer.addresses.map((address, index) => {
                return (
                  <div className={items[index]} key={index}>
                    <AddressView {...address} />
                  </div>
                );
              })
            }
          </div>
          <div className="footer-spliter"></div>
          <div className="footer-status">
            <div className="app-icons">
              {
                footer.leftLinks.map((link, index) => {
                  return (<LinkView {...link} key={index} />);
                })
              }
            </div>
            <div>
              {
                footer.rightLinks.map((link, index) => {
                  return (<LinkView {...link} key={index} />);
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class LinkView extends React.Component<Link> {

  constructor(props: Link) {
    super(props);
    this.openLink = this.openLink.bind(this);
  }

  openLink() {
    window.openLink(this.props.link, this.props.internal);
  }

  render() {
    const link = this.props;
    if (link.type === "icon") {
      return (
        <div className={"app-icon " + link.name} onClick={this.openLink}>
        </div>
      );
    } else {
      return (
        <span className="footer-link" onClick={this.openLink}>
          {link.name}
        </span>
      );
    }
  }
}

class AddressView extends React.Component<Address> {
  render() {
    const address = this.props;
    const imageClassName = "address-grid-image-view " + ((address.local) ? "local-office" : "us-office");
    return (
      <div className="address-grid-container grid-address-container-content">
        <div className="address-grid-content-view">
          <div className="footer-address-title">
            {address.title}
          </div>
          <div className="description">
            <Description list={address.lines} />
          </div>
        </div>
        <div className={imageClassName}>
        </div>
      </div >
    );
  }
}