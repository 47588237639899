//Library Imports
import ReactDOM from 'react-dom';
//Library Imports CSS

//Local Imports
import reportWebVitals from './reportWebVitals';
import Screen from './templates/Screen';
//Local Imports CSS
import './templates/style.css';


ReactDOM.render(<Screen />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
