//Library Imports
import React from 'react';
//Library Imports CSS
//Local Imports
import Description from './Description';
import { PageProps, Product } from './Model';
import api from './API';
//Local Imports CSS

export default class ProductView extends React.Component<PageProps, Product> {

  constructor(props: PageProps) {
    super(props);
    this.state = {
      title: "",
      description: [],
      box: "right",
      background: "",
      link: "",
      internal: false
    };
    this.openLink = this.openLink.bind(this);
  }

  openLink() {
    window.openLink(this.state.link, this.state.internal);
  }

  componentDidMount() {
    api.getProduct(this.props.name).then((product) => {
      this.setState(product);
    });
  }

  render() {
    const product = this.state;
    const right = (product.box === "right");
    const boxCss = right ? "right-box" : "left-box";
    return (
      <div className="grid-container">
        <div className="grid-content-view">
          <div className={"big-title black-font"}><span className={boxCss}>{product.title}</span></div>
          <div className="description"><Description list={product.description} /></div>
          <div className="product-action">
            <button className={"demo-button button-action " + ((right) ? "right-demo-button" : "left-demo-button")} onClick={this.openLink}>
              Request for demo
            </button>
          </div>
        </div>
        <div className={"grid-image-view " + product.background}>
        </div>
      </div>
    );
  }
}